import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import Checkers from "../components/common/Checkers"
import Newsletter from "../components/common/Newsletter"
import IndexEvents from "../components/events/IndexEvents"
import HeroMovie from "../components/index/HeroMovie"
import ParallaxIndex from "../components/index/ParallaxIndex"

import Layout from "../components/layout"
import LokalerIndex from "../components/lokaler/LokalerIndex"
import LokalerIndexAlt from "../components/lokaler/LokalerIndexAlt"

import SEO from "../components/seo"

export default function IndexPage(props) {
  const lang = props.pageContext.langKey

  return (
    <Layout isHome lang={lang}>
      <SEO title="Slagthuset" isHome />
      <div className="">
        <div className="max-w-screen-2xl mx-auto px-4 lg:px-8 py-8  ">
          <IndexEvents lang="sv" />

          {/* <HeroMovie /> */}
        </div>

        {/* <div className=" bg-thirteenth text-white">
          <div className=" max-w-screen-2xl mx-auto  p-4 lg:p-8">
            <LokalerIndex />
          </div>
        </div> */}
        <div className="bg-thirteenth/30">
          <LokalerIndexAlt />
        </div>
        <div className="hidden lg:block">
          <ParallaxIndex />
        </div>
        <div className="">
          <div className="max-w-2xl mx-auto px-4 lg:px-8 py-4 lg:py-16">
            <Newsletter />
          </div>
        </div>
      </div>
    </Layout>
  )
}
